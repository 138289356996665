/* General Styling */
.days-editor {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
}

/* List Style: One Item Per Row */
.days-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Individual Day Box */
.day-card {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  text-align: center;
  cursor: grab;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.day-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Title Clickable Toggle */
.title-toggle {
  cursor: pointer;
  margin: 0;
  font-size: 18px;
  color: #333;
}

/* Input Container */
.input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 6px;
}

/* Dropdown Style */
.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-container select {
  padding: 8px;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Drag & Drop File Upload */
.dropzone-container {
  margin-top: 10px;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  min-height: 150px;
}

.dropzone-container:hover {
  border-color: #007bff;
}

/* Image Preview */
.uploaded-image {
  max-width: 120px;
  height: auto;
  border-radius: 6px;
  margin-top: 5px;
}

/* Color Picker */
.color-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Color Picker Wrapper */
.color-picker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Color Box */
.color-box {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* Color Picker Popup */
.color-picker-popup {
  position: absolute;
  z-index: 1000;
  top: 40px;
  left: 0;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 5px;
}


.day-card {
  background: #fff;
  margin: 8px 0;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Flex container to keep the grip icon on the left and title on the right */
.day-card-header {
  display: flex;
  align-items: center;
  gap: 8px; /* spacing between icon and title */
}

/* The handle for dragging */
.drag-handle {
  cursor: grab;
  user-select: none;
  /* optionally, some padding to make the icon clickable area bigger */
  padding: 4px;
}

.drag-handle:active {
  cursor: grabbing;
}

.color-picker-popup{
  z-index: 100000000000 !important;
}

/* Additional styling for the rest of the elements */