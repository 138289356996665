.chat-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  height: 500px; /* Set a maximum height for the chat container */
  overflow-y: auto; /* Add a vertical scrollbar when content overflows */
}

/* Set the scrollbar to be active after 80% height is reached */
.chat-container::-webkit-scrollbar {
  width: 12px;
}

.chat-container::-webkit-scrollbar-thumb {
  /* background-color: #007bff; */
  border-radius: 12px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3;
}

.user-message {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding: 10px;
  max-width: 70%;
  align-self: flex-start;
  margin: 5px 10px 5px 0;
  border-radius: 10px 10px 10px 0;
}
.user-message-time {
  font-size: 10px;
  color: #ffffff;
  text-align: right;
  margin-top: 5px;
  font-style: italic;
}
.admin-message-time {
  font-size: 10px;
  color: #999;
  text-align: right;
  margin-top: 5px;
  font-style: italic;
}

.admin-message {
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  max-width: 70%;
  align-self: flex-end;
  margin: 5px 0 5px 10px;
  border-radius: 10px 10px 0 10px;
}
.reply-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

/* Add media query for smaller screens */
@media only screen and (max-width: 600px) {
  .reply-box {
    flex-direction: column; /* Stack items vertically on smaller screens */
    text-align: center; /* Center text when in column layout */
  }
}

.date-stamp {
  text-align: center;
  font-size: 12px; /* Adjust the font size as needed */
  color: #333; /* You can use your preferred text color */
  margin: auto; /* Adjust margin as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; /* Center and style the date */
  border-radius: 5px;
  background-color: #bcbcbc;
  padding: 2px 4px;
}

.reply-textarea {
  width: 92%;
  height: 50px;
  /* margin-bottom: 20px; */
  margin-right: 10px;
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 6px;
  font-size: 16px;
  resize: none;
}

.reply-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  height: 50px;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

/* Media query for smaller screens */
@media only screen and (max-width: 1825px) {
  .reply-button {
    /* height: 40px; */
    /* padding: 8px 16px; */
    font-size: 14px;
  }
}
@media only screen and (max-width: 1625px) {
  .reply-button {
    /* height: 40px; */
    /* padding: 8px 16px; */
    margin: 5px;
    font-size: 12px;
  }
}

.reply-button:hover {
  background-color: #0056b3;
}

.react-bootstrap-table {
  overflow: hidden;
}
.custom-overflow-hidden {
  overflow: hidden !important;
}
