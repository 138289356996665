.venue-timings .timing-table {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }
  
  .venue-timings .timing-row {
    display: contents;
  }
  
  .day-name {
    font-weight: bold;
    padding-right: 10px;
  }
  
  .day-name, .timing-row td {
    padding: 5px;
    border-bottom: 1px solid #ccc;
  }