.account-info {
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 0;
}

.account-info__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #343a40;
  text-align: center;
}

.account-info__buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.account-info__button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.account-info__button:hover {
  background-color: #0056b3;
}

.pricing-section {
  text-align: center;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #343a40;
}

.pricing-table {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.pricing-plan,
.pay-as-you-go-plan {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  flex: 1;
  max-width: 350px;
  min-width: 300px;
  box-sizing: border-box;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
}

.pricing-plan {
  min-height: 932px; /* original height */
}

.pay-as-you-go-plan {
  min-height: 500px; /* decreased height */
}

.pricing-plan h3, .pay-as-you-go-plan h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #007bff;
}

.pricing-plan p, .pay-as-you-go-plan p {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #343a40;
}

.pricing-plan ul, .pay-as-you-go-plan ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 15px 0;
  color: #343a40;
}

.pricing-plan li, .pay-as-you-go-plan li {
  margin-bottom: 8px;
  font-size: 1rem;
  position: relative;
  padding-left: 25px;
}

.pricing-plan li::before, .pay-as-you-go-plan li::before {
  content: '\2713';
  font-size: 14px;
  color: #E52534 !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #E52534 !important;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.pricing-plan .button, .pay-as-you-go-plan .button {
  background-color: #E52534 !important;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pricing-plan.basic, .pricing-plan.basic-benefits, .pricing-plan.add-on-package, .pricing-plan.get-promoted, .pricing-plan.pay-as-you-go {
  border-radius: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.pricing-plan .button:hover, .pay-as-you-go-plan .button:hover {
  background-color: #0056b3;
}

.pay-as-you-go-plan:hover, .pricing-plan:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #6c757d;
}

.button:hover:not(:disabled) {
  background-color: #0056b3;
}



.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #E52534 !important;
  color: white;
}

.pricing-section {
  text-align: center;
}

.pricing-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pricing-plan,
.pay-as-you-go-plan {
  border: 1px solid #dee2e6;
  padding: 20px;
  margin: 10px;
  text-align: left;
}

.pricing-plan h3,
.pay-as-you-go-plan h3 {
  margin-bottom: 10px;
}

.pricing-plan ul,
.pay-as-you-go-plan ul {
  list-style: none;
  padding: 0;
}

.pricing-plan li,
.pay-as-you-go-plan li {
  margin-bottom: 5px;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.pricing-table {
  background-color: #F9FBFC !important;
}

.pricing-section .pricing-plan {
  width: 20%;
}

.page-content {
  background-color: #F9FBFC !important;
}

.blue-bg {
  background-color: #E52534 !important;
  color: white !important;
}

.pricing-plan h3 {
  color: #000 !important;
  background-color: #fff !important;
}

.pricing-plan.blue-bg h3 {
  color: white !important;
  background-color: #E52534 !important;
}

.blue-bg p,
.blue-bg li {
  color: white !important;
}

.blue-bg .button {
  background-color: white !important;
  color: #E52534 !important;
}

.blue-bg .button:hover {
  background-color: #f1f1f1 !important;
}

.pricing-plan span {
  font-size: 2.5rem;
  line-height: 32px;
}

.pricing-plan p {
  font-size: 1rem !important;
  color: #000 !important;
}

.pricing-plan.blue-bg p {
  color: #fff !important;
}

.pricing-plan li {
  text-align: left;
}

.pay-as-you-go-plan li {
  text-align: left;
}

.pay-as-you-go-plan {
  text-align: center;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px 0;
  flex: 1;
}

.plan-features li {
  margin-bottom: 10px;
}
