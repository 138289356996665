.event-card {
    border: 4px solid;
    padding: 20px;
    border-radius: 28px;
    margin-bottom: 20px;
    background-color: #212121;
    padding-bottom: 48px;
    padding-top: 48px;
  }

  .event-status{
    display: inline-block;
    top: 0;
    position: absolute ;
    right: 2.2%;
    padding: 8px 16px;    
    color: #fff;
    font-size: 1rem;
    border-top-right-radius: 28px; /* Adjust the radius as needed for the top-left corner */
    border-bottom-left-radius: 15px; 
  }
  
  .event-image {   
    height: 140px;
    width: 40%;
    border-radius: 25px;
    object-fit: cover;
  }

  .top-half{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .event-card .text-info .venue-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    line-height: 14px;
  }

  .event-card .text-info .venue-location {   
    color: #434343;  
    font-size: 0.9rem;
  }

  .venue-info{
    display: flex;
    align-items: center;  
    gap: 12px;  
  }

  .text-container {
    width: 60%;
    padding-left: 20px; /* Optional, for spacing between image and text */
  }
  
  .event-logo img {
    height: 60px;
    width: auto;
    border-radius: 50%;
  }
  
  .venue-name,
  .venue-location,
  .event-date-time,
  .event-title,
  .created-date {
    margin: 5px 0;
  }


  .event-users {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .invited-users {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .user-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
  }
  
  .user-avatar img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-bottom: 4px;
  }
  
  .user-name {
    color: #fff;
    font-size: 0.8rem;
    margin-top: 4px;
  }
  
  .user-status-icon {
    margin-top: 2px;
    position: absolute;
    top: 70%;  
    margin-left: 24px;  
  }

  .user-status-icon span {
    font-size: 1.2rem;
  }
  
  .more-users {    
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 20px;
    font-size: 1rem;
    margin-right: 10px;
  }
  
  .extra-guests {
    background-color: #555;
    color: #fff;
    padding: 4px;    
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    height: 60px;
    margin-bottom: 8px;
  }
  
  .extra-guests-count {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .extra-guests-label {
    font-size: 0.7rem;
  }


  .user-name {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 0.8rem;
  }


  
