:root {
    --primary-color: #E22A2A;
    --sec-color: #E22A2A;
    --tab-width: 180px; /* 20% of the viewport width */
    --tab-height: 400px; /* adjust this value as needed */
  }

  #extab2 {
    background: inherit !important;
  }

  .custom-card {
    position: relative;
  }
  
  .cover-image {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .profile-image {
    position: absolute;
    top: 228px;
    left:20px;
    width: 80px;
    height: 80px;  
    border-radius: 50%;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  #exTab2.twitter-like-tabs2 {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #657786;
    display: flex;
  }
  
  #exTab2.twitter-like-tabs2 > ul {  
    margin:  0;
    padding: 0;
    list-style-type: none;
    width: var(--tab-width);
    min-width: var(--tab-width); /* Adding min-width here */
    height: var(--tab-height);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); /* This line adds the shadow effect */
  }
  
  #exTab2.twitter-like-tabs2 > ul > li {
    display: flex; /* Use flexbox */
    align-items: center; /* Center vertically */
    justify-content: center;            /* Center horizontally */
    text-align: center;
    padding: 10px;   
    cursor: pointer;
    margin: 0 !important;
  }
  
  #exTab2.twitter-like-tabs2 > ul > li:hover,
  #exTab2.twitter-like-tabs2 > ul > li.active {
    background-color: var(--sec-color) !important;   
    border-bottom: none !important;
    text-decoration: none !important;
  }

  #exTab2.twitter-like-tabs2 > ul > li:hover > a,
  #exTab2.twitter-like-tabs2 > ul > li.active > a {   
    color: white !important;    
    text-decoration: none !important;
    border-bottom: none !important;
  }

  #exTab2.twitter-like-tabs2 > ul > li > a:hover {       
        text-decoration: none;
        background-color: inherit !important;
    }
  
  
    #exTab2.twitter-like-tabs2 > .tab-content {
    flex-grow: 1;      
    padding: 20px;
    overflow-y: auto; /* In case the content exceeds the container height */
  }
  
  #exTab2.padded-tab {
    padding: 15px;
    min-height: 200px;
  }

  #exTab2.twitter-like-tabs2 .nav-pills {
    border-bottom: none;
}

#exTab2.twitter-like-tabs2 > ul > li > a {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  