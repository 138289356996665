.three-state-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #FDCCFF;  Light pinkish background */
  background-color: #F9FBFC;
  padding: 8px;
  border-radius: 6px;
}

.button-group {
  display: flex; 
  overflow: hidden;
  /*background-color: #FDCCFF;  Light pinkish background */
  background-color: #F9FBFC;
}

.toggle-button {
  padding: 8px 12px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6c63ff; /* Purple text color */
  background: none;
  border: none;
  outline: none;
  font-size: 12px;
  /* font-weight: bold; */
  border-radius: 6px; 
}

.toggle-button:hover {
  background-color: #e0c7ff; /* Light purple background on hover */
}

.selected-cancelled {
  background-color: #E22732;
  color: white;
}

.selected-pending {
  background-color: #239FC1;
  color: white;
}

.selected-confirmed {
  background-color: #4caf50;
  color: white;
}

.button-group .toggle-button:not(.selected-cancelled):not(.selected-pending):not(.selected-confirmed):hover {
  background-color: #e0c7ff; /* Light purple background on hover */
}
