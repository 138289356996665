.multi-selected {
  .displayNone {
    display: block !important;
  }

  .optionListContainer {
    background: #fff;
    border-radius: 4px;
    margin-top: 1px;
    position: static;
    width: 50%;
    z-index: 2;
  }

  .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 22px;
    position: initial !important;
    padding: 0px !important;
    width: 50%;
    position: relative;
  }

  .multiSelectContainer {
    display: flex;
    position: relative;
    text-align: left;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }

  .optionListContainer {
    position: relative !important;
    width: 50% !important;
  }

  .optionContainer li {
    list-style: none;
  }

  .chip:hover {
    background: #0096fb !important;
    color: white !important;
    left: 0px;
    width: 105% !important;
  }

  .chip {
    position: relative;
    align-items: center;
    background: transparent !important;
    border-radius: 0px !important;
    display: block !important;
    padding: 10px !important;
    color: #000 !important;
    margin-bottom: 0px !important;
  }

  .searchWrapper,
  .optionContainer {
    height: 175px;
    margin-top: 4rem;
    overflow-x: hidden;
  }

  .multiSelectContainer input {
    display: none;
  }

  .closeIcon {
    cursor: pointer;
    height: 38px !important;
    width: 102% !important;
    position: absolute;
    background: blue;
    top: 0;
    left: 0px !important;
    height: 24px;
    opacity: 0;
  }

  .multiSelectContainer input {
    display: block;

    border: 1px solid #e2e5e8 !important;
    width: 100%;
    position: absolute;
    background: white !important;
    top: -2px;
    font-size: 16px !important;
    left: 0;
    height: 43px !important;
    z-index: 1000;
    border-radius: 5px;
    padding: 0.8rem;
  }

  .multiSelectContainer li:hover {
    background: #71b6f9 !important;
    color: #000;
    opacity: 0.7;
    cursor: pointer;
  }

  .highlightOption {
    background: none !important;
    color: #000;
    opacity: 0.7;
  }
}
