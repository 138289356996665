/* Subscriptionlist.css */

.account-info {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.account-info__title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.account-info__buttons {
  display: flex;
  gap: 10px;
}

.account-info__button {
  padding: 10px 20px;
  background-color: #E3232B !important;
  color: white;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
}

.account-info__button:hover {
  background-color: #C5232B !important;
}

.pricing-table {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* .pricing-plan {
  flex: 1 1 30%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.pricing-plan h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.pricing-plan p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.pricing-plan ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px 0;
}

.pricing-plan li {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.button {
  /* padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer; */
}

.button:disabled {
  background-color: #6c757d;
}

.button:hover:not(:disabled) {
  background-color: #C5232B;
}

@media (max-width: 768px) {
  .account-info{
    width: 50%;
  }
}

@media (max-width: 576px) {
  .account-info{
    width: 100% !important;
  }

  .pricing-plan {
    width: 80% !important;
    margin-bottom: 32px;
  }

}


