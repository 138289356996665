.suggestion-box {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .suggestion-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align items to left and right */
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f2f2f2;
  }
  
  .suggestion-text {
    font-weight: bold;
  }
  
  .suggestion-badge {
    margin-left: 10px;
  }