.custom-card-background {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .custom-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
    z-index: 1;
    border-radius: 0.5rem;
  }
  
  .custom-card-body {
    position: relative;
    z-index: 2;
    color: white;
  }
  
  .learn-more-link {
    display: inline-block;
    margin-top: 10px;
  }
  