/* Chat.css */
.chat-container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto; 
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #292F3C; /* Dark background */
  color: white;
}

.chat-input-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.chat-input {
  width: 100%;
  color: white !important;
}

.chat-input input {
  color: white !important;
}

.placeholder-text {
  color: white !important;
}

.upload-button {
  margin-right: 10px;
  background-color: black;
  color: white;
}

.send-button {
  background-color: #F9279A;
  color: white;
  height: 50px;
  width: auto;
}

.message-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.message-bubble {
  background-color: #E42C2C;
  color: white;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  max-width: 70%;
  word-wrap: break-word;
}

.message-time {
  color: gray;
  margin-top: 4px;
}

.admin-message .message-bubble {
  background-color: #007bff;
}

.user-message .message-bubble {
  background-color: #F9279A;
  margin-right: 16px;
}


.send-button-custom {
    background-color: #E42C2C !important;
    color: white;
  }
