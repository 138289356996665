.custom-card {
    background: #fff;   
    box-shadow: 5px 22px 0 rgba(0, 0, 0, 0.04), 0 0 0 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 1rem;
     border: solid 1px #DCDFE4 !important;   
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 20px !important;
}

.custom-card-body {
    padding: 1.5rem;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
}

.card-statistics h3 {
    font-size: 1.5rem;
    color: #333;
}

.card-statistics p {
    color: #666;
}

.card-statistics .text-primary {
    color: #0061C2;
    text-decoration: none;
}

.card-statistics .text-primary:hover {
    text-decoration: underline;
}

.apex-charts {
    margin-top: 1rem;
}

button.btn.btn-primary {
    background-color: #E4272D;
    border-color: #E4272D;
}

.table-shorting ul.pagination > li.active > a {
    background-color: #E4272D; 
    border-color: #E4272D;   
}
