/* custom.css */
.highlight-red {
    color: red;
    text-decoration: line-through;
  }
  
  .highlight-green {
    color: green;
  }
  
  .card-custom {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .tag {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 15px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    font-size: 12px;
  }
  
  .tag-added {
    background-color: #e0f8e0;
    border: 1px solid #4caf50;
    color: #4caf50;
  }
  
  .tag-removed {
    background-color: #fbe6e6;
    border: 1px solid #f44336;
    color: #f44336;
  }
  
  .location-container {
    display: flex;
    justify-content: space-between;
  }
  
  .location-map {
    width: 45%;
  }
  
  .timing-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .timing-table th, .timing-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .timing-table th {
    background-color: #f2f2f2;
  }
  
  .gallery-card {
    display: flex;
    flex-wrap: wrap;
  }
  
  .gallery-item {
    flex: 1 0 21%; /* Adjust to show 4 items per row */
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    max-width: 250px;
    margin-bottom: 10px;
  }


  .image-wrapper {
    display: inline-block;
    margin: 5px;
  }
  
  .image-wrapper img {
    max-width: 200px;
    max-height: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }
  