:root {
    --primary-color: #E22A2A;
    --sec-color: #E22A2A;
    --tab-active-color: #E22A2A;
  }
  
  .custom-card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin: 20px;
  }
  
  .cover-image {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .profile-image {
    position: absolute;
    top: 220px;
    left: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .tab-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  .tab.active {
    background: var(--tab-active-color);
    color: white;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background: #f1f1f1;
  }
  