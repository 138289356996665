/* CreateUser.css */

.permission-group {
    margin-bottom: 1rem;
  }
  
  .permission-group-single {
    margin-bottom: 1rem;
    margin-right: 2rem;
  }
  
  .parent-permission {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .child-permission {
    padding-left: 1.5rem;
    margin-bottom: 0.3rem;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  /* .btn-primary {
    margin-bottom: 1rem;
  } */
  
  .page-title-box {
    margin-bottom: 1.5rem;
  }
  
  .form-check-label {
    margin-left: 0.5rem;
  }
  
  .form-check-input {
    margin-left: 1rem;
  }
  
  .modal-header {
    font-size: 1.5rem;
  }
  
  .modal-body {
    padding: 2rem;
  }
  
  .modal-footer {
    padding: 1rem;
  }
  
  .permission-group-single .form-check-input {
    transform: scale(1.2);
  }
  
  .permission-group-single .form-check-label {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
  