:root {
  --primary-color: #E22A2A;
  --sec-color: #E22A2A;
  --tab-width: 180px; /* 20% of the viewport width */
  --tab-height: 400px; /* adjust this value as needed */
}

#extab2 {
  background: inherit !important;
}

.custom-card {
  position: relative;
}

.cover-image {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-image {
  position: absolute;
  top: 228px;
  left:20px;
  width: 80px;
  height: 80px;  
  border-radius: 50%;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

#exTab2.twitter-like-tabs2 {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #657786;
  display: flex;
}

#exTab2.twitter-like-tabs2 > ul {  
  margin:  0;
  padding: 0;
  list-style-type: none;
  width: var(--tab-width);
  min-width: var(--tab-width); /* Adding min-width here */
  height: var(--tab-height);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); /* This line adds the shadow effect */
}

#exTab2.twitter-like-tabs2 > ul > li {
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center;
  padding: 10px;   
  cursor: pointer;
  margin: 0 !important;
}

#exTab2.twitter-like-tabs2 > ul > li:hover,
#exTab2.twitter-like-tabs2 > ul > li.active {
  background-color: var(--sec-color) !important;   
  border-bottom: none !important;
  text-decoration: none !important;
}

#exTab2.twitter-like-tabs2 > ul > li:hover > a,
#exTab2.twitter-like-tabs2 > ul > li.active > a {   
  color: white !important;    
  text-decoration: none !important;
  border-bottom: none !important;
}

#exTab2.twitter-like-tabs2 > ul > li > a:hover {       
      text-decoration: none;
      background-color: inherit !important;
  }


  #exTab2.twitter-like-tabs2 > .tab-content {
  flex-grow: 1;      
  padding: 20px;
  overflow-y: auto; /* In case the content exceeds the container height */
}

#exTab2.padded-tab {
  padding: 15px;
  min-height: 200px;
}

#exTab2.twitter-like-tabs2 .nav-pills {
  border-bottom: none;
  
}

#exTab2.twitter-like-tabs2 > ul > li > a {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #E22A2A; */
}

.padded-tab {
  padding: 15px;
  min-height: 200px;
}

.twitter-like-tabsbm {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #657786;
  margin: 0;
  padding: 0;
}

.twitter-like-tabsbm > ul {
  display: flex;
  justify-content: flex-start;
  /* margin: 0 30%; */
}

/* .twitter-like-tabsbm .nav-pills {
  border-bottom: 1px solid #e1e8ed;
} */

.twitter-like-tabsbm .tab-content > .tab-pane {
  background-color: transparent !important;
  color: #657786;
}
.twitter-like-tabsbm .nav-pills > li {
  margin-right: 50px;
  /* flex-grow: 1; */
  text-align: center;
  padding: 0px;
}

.twitter-like-tabsbm .nav-pills > li > a {
  border-radius: 0;
  font-size: 16px;
  color: #657786;
  background-color: transparent;
  border-bottom: 2px solid transparent;
}

.twitter-like-tabsbm .nav-pills > li.active > a,
.twitter-like-tabsbm .nav-pills > li > a:hover {
  color: #E22A2A;
  border-bottom-color: #E22A2A;
}

.twitter-like-tabsbm .nav-pills > li > a:hover {
  background-color: #e8f5fe; /* Adjust color as needed */
}

.twitter-like-tabsbm .padded-tab {
  padding: 15px;
  min-height: 100px;
}