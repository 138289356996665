:root {
  --primary-color: #E22A2A;
  --sec-color: #E22A2A;
  --card-bg: #f8f9fa;
  --card-dark-bg: #333;
  --text-dark: #000;
  --text-light: #fff;
  --shadow-color: rgba(0, 0, 0, 0.15);
}

body {
  background-color: #F7F6FB;
}

/* Booking Table Styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.custom-table thead th {
  background-color: #222;
  color: #fff;
  text-align: center;
  padding: 12px;
}

.custom-table tbody tr {
  transition: background-color 0.2s ease-in-out;
  background: #fff;
}

.custom-table tbody tr:hover {
  background-color: #f0f0f0;
}

.custom-table td,
.custom-table th {
  vertical-align: middle;
  text-align: center;
  padding: 12px;
  border: 1px solid #ddd;
}

/* Offer & Package Cards */
.offer-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.offer-card {
  background-color: var(--card-bg);
  color: var(--text-dark);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 350px;
}

.offer-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px var(--shadow-color);
}

.offer-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.offer-desc {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.offer-image {
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 2px 5px var(--shadow-color);
  cursor: pointer;
}

.package-info p {
  margin: 5px 0;
  font-size: 0.95rem;
}

.status-text {
  font-weight: bold;
  color: var(--primary-color);
}

/* Venue Details Card */
.custom-card {
  position: relative;
  background-color: var(--card-bg);
  border-radius: 10px;
  box-shadow: 0px 4px 10px var(--shadow-color);
  overflow: hidden;
  padding: 20px;
}

.cover-image {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.profile-image {
  position: absolute;
  top: 130px;
  left: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px var(--shadow-color);
  overflow: hidden;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Light Background for Sections */
.section-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px var(--shadow-color);
  margin-bottom: 20px;
}

/* Responsive Cards */
@media (max-width: 768px) {
  .offer-section {
      grid-template-columns: 1fr;
  }
}
