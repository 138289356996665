.user-list {
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.user-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.user-item img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}

.user-item p {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}
