.badge-success {
    background-color: #28a745; /* Bootstrap green */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .badge-inactive {
    background-color: #dc3545; /* Bootstrap red */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .badge-primary {
    background-color: #007bff; /* Bootstrap blue */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }