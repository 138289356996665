.story-slider .slick-slide {
    text-align: center;
    margin: 0 10px;
  }
  
  .story {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .story-icon {
    width: 60px; /* Adjust based on your design */
    height: 60px; /* Adjust based on your design */
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-bottom: 4px;
    position: relative;
    border: 1px solid rgb(241, 11, 50); /* Adjust color based on your design */
    
  }
  
 
  .story-name {
    color: white; /* Adjust based on your design */
    font-size: 12px; /* Adjust based on your design */
  }
  