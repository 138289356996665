.date-range-picker {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: stretch;
}

.col5 {
    flex-basis: calc(50% - 0.5rem);
}

.col2 {
    flex-basis: calc(20% - 0.5rem);
    display: flex;
    justify-content: center;
    align-items: stretch; 
}

.date-label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: bold;
}

.fancy-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    transition: box-shadow 0.3s ease;
}

.fancy-input:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
}

button.fancy-button {
    width: 100%;
    height: 100%; /* This makes the button fill its parent's height */
    padding: 10px 20px;
    background-color: #E52534;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover.fancy-button {
    background-color: #0056b3;
}