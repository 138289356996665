/*
Template Name: Lexa - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datatable";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartist";
@import "custom/plugins/c3";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/timeline";
@import "custom/pages/directory";


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');


// device min-widths

@media (min-width: 1600px) {
    .table-shorting select.form-select {
        width: 10% !important;
    }

    .large-screen{
        display: block;
    }

    .medium-screen{
        display: none;
    }

    .col-xxxl-3 {
        flex: 0 0 auto;
        width: 25%; /* 4 columns in a row */
    }
}

@media (max-width: 1599px) {
    .large-screen{
        display: none;
    }

    .medium-screen{        
        display: block;
    }
}



.modal-body .table-shorting select.form-select {
    width: 100% !important;
}

.Toastify__toast-body > div:last-child {
    text-align: left !important;
}



