/* CalendarWithStats.css */

.calendar-card .card-body {
    padding: 1rem; /* Set padding specifically for calendar card */
}

.rbc-calendar {
    background: #fff;
    border-radius: 10px;
    padding: 0px;   
}

.rbc-toolbar {
    background: none;
    color: black;
    border-radius: 5px;
    margin-bottom: 10px;
}

.rbc-toolbar button {
    color: black;
}

.rbc-month-view,
.rbc-time-view {
    border: none;
}

/* .rbc-day-bg {
    background: transparent;
} */

.rbc-today {
    background: #e9ecef;
}

.slim-badge {
    display: block;
    margin: 2px 0;
    font-size: 0.75rem;
    padding: 0.25em 0.5em;
    background: none;
    border: none;
}

.event-badges {
    margin-top: 15px !important;
    display: flex;
    flex-direction: column;
}

.btn-outline-primary {
    color: #6f42c1;
    border-color: #6f42c1;
}

.btn-outline-primary:hover {
    background-color: #6f42c1;
    color: white;
}

.btn-primary {
    background-color: #6f42c1;
    border-color: #6f42c1;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: inherit !important;
    padding: 0 !important;
}

.rbc-event-content {    
    overflow: visible !important;
    font-size: 0.8rem;
    font-weight: 400;
}